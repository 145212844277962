body {
  margin: 0;
  background-color: #7CFF8F;
}

/* Navbar Styles */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: #333;
  color: white;
}

.navbar-logo {
  cursor: pointer;
}

.nav-links {
  display: flex;
  gap: 15px;
  list-style: none;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}

.nav-links li a:hover {
  color: #FFBA93;
}

.nav-logo {
  height: 100;
  width: 100;
}

/* Mobile Styles */
.menu-icon {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.nav-links-mobile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: 48px; /* Adjust this based on your navbar height */
  left: 0; /* Align to the left edge */
  right: 0; /* Align to the right edge */
  background-color: #333;
  padding: 15px 20px; /* Add some horizontal padding for a clean look */
  border-radius: 0; /* Remove rounded corners to span fully */
  width: 100%; /* Ensure it spans the full width */
}

.nav-links-mobile li {
  text-align: center; /* Centers text inside the menu items */
  padding: 10px 0; /* Adds vertical spacing */
  list-style: none; /* Removes default bullet points */
  border-bottom: 1px solid #444; /* Optional: Separates items with a thin line */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.nav-links-mobile li:last-child {
  border-bottom: none; /* Removes border from the last item */
}

.nav-links-mobile li a {
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  display: block;
  transition: color 0.3s ease;
}

.nav-links-mobile li:hover {
  background-color: #444;
}

.nav-links-mobile li a:hover {
  color: #00d9ff;
}

/* Responsive */

@media screen and (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .menu-icon {
    display: block;
  }
}

.section {
  padding: 20px 35px;
  margin-bottom: 30px;
}

h2.title {
  font-size: 35px;
  position: relative;
  animation: drip 1s ease-in-out infinite;
}

p.text {
  font-size: 20px;
  line-height: 1.8;
}

.logo-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.numspan {
  font-size: 35px;
  font-weight: bold;
  margin-right: 10px;
}

.logo-container div {
  text-align: center;
}

h2.tagline {
  font-size: 25px;
  padding: 20px;
}

.app-logo {
  height: 300px;
  width: 300px;
  animation: bounce 2s infinite ease-in-out;
}

/* Animations styles */

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
}

@keyframes drip {
  0% {
      top: 0;
      opacity: 1;
  }
  50% {
      top: 30px; /* Distance of the drip drop */
      opacity: 0.6;
  }
  100% {
      top: 0;
      opacity: 1;
  }
}

/* Footer styles */

.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #222;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  font-weight: bold;
  font-size: 16px;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #FFBA93;
}

@media (max-width: 600px) {
  .footer-link {
    font-size: 14px;
    margin: 0 8px;
  }
}
